import React, {useEffect} from "react"
import EditarPerfilComponent from "../../components/EditarPerfilComponents/EditarPerfil"
import LayoutProvider from "../../../src/components/Layout"
import { navigate } from 'gatsby';

const EditarPerfilPage = ({ location }) => {
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } 
  }, [])

  return(
  
  <LayoutProvider title="Editar Perfil" location={location}>
    {/* <p className="title-editar-ss">Editar Perfil</p> */}

    <div className="contenedor p-2 ">


      <EditarPerfilComponent />
    </div>
    
  </LayoutProvider>
)}

export default EditarPerfilPage
