import { toast } from "react-toastify"
import moment from "moment"

export function ValidaEditarPerfil(
  valores,
  Error,
  setError,
  EditarDatos,
  setEditarDatos,
  setActualizando,
  NumeroTlf
) {
  var error = false
  setActualizando(true)

  if (valores.nombre === "") {
    toast.error("El nombre obligatorio.")

    setError({
      ...Error,
      nombre: true,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.apellido_p === "") {
    toast.error("El apellido paterno obligatorio.")

    setError({
      ...Error,
      apellido_p: true,
      nombre: false,
    })
    setActualizando(false)
    return (error = true)
  }

  var fechanacimiento = valores.fecha_nacimiento
  let hoy = new Date()

  let edad = moment(hoy).diff(fechanacimiento, "years")
  if (edad < 18) {
    toast.error("Debe tener al menos 18 años.")
    setError({
      ...Error,
      fecha_nacimiento: true,
      apellido_p: false,
    })
    setEditarDatos({
      ...EditarDatos,
      fecha_nacimiento: "",
    })

    return
  } else if (edad > 120) {
    toast.error("La fecha de nacimiento es inválida.")
    setError({
      ...Error,
      fecha_nacimiento: true,
      apellido_p: false,
    })
    setEditarDatos({
      ...EditarDatos,
      fecha_nacimiento: "",
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.fecha_nacimiento === "" || valores.fecha_nacimiento === null) {
    toast.error("La fecha de nacimiento es obligatoria.")

    setError({
      ...Error,
      fecha_nacimiento: true,
      apellido_p: false,
    })
    setActualizando(false)
    return (error = true)
  }
  
  if (valores.genero_id === "" || valores.genero_id === null) {
    toast.error("El género es obligatorio.")

    setError({
      ...Error,
      genero_id: true,
      fecha_nacimiento: false,
    })
    setActualizando(false)
    return (error = true)
  }
  
  if (valores.sexo_id === "" || valores.sexo_id === null) {
    toast.error("El sexo es obligatorio.")

    setError({
      ...Error,
      sexo_id: true,
      genero_id: false,
    })
    setActualizando(false)
    return (error = true)
  }
  

  if (valores.nacionalidad_id === "" || valores.nacionalidad_id === null) {
    toast.error("La nacionalidad es obligatoria.")

    setError({
      ...Error,
      nacionalidad_id: true,
      sexo_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.pais_origen_id === "" || valores.pais_origen_id === null) {
    toast.error("El país origen es obligatorio.")

    setError({
      ...Error,
      pais_origen_id: true,
      nacionalidad_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (NumeroTlf !== "" && NumeroTlf !== null) {
    if (NumeroTlf.length < 8 || NumeroTlf.length > 13) {
      toast.error("El número de teléfono es inválido.")

      setError({
        ...Error,
        telefono_uno: true,
        pais_origen_id: false,
        sexo_id: false,
      })
      setActualizando(false)
      return (error = true)
    }
  }else{
    toast.error("El número de teléfono es obligatorio.")

    setError({
      ...Error,
      telefono_uno: true,
      pais_origen_id: false,
      sexo_id: false,
    })
    setActualizando(false)
    return (error = true)
  }
  

  if (valores.telefono_dos !== "" && valores.telefono_dos !== null) {
    if (valores.telefono_dos.length < 7 || valores.telefono_dos.length > 12) {
      toast.error("El número de teléfono fijo es inválido.")
      setError({
        ...Error,
        telefono_dos: true,
        telefono_uno: false,
        sexo_id: false,
      })
      setActualizando(false)
      return (error = true)
    }
  }

  if (valores.region_id === "" || valores.region_id === null) {
    toast.error("La región es obligatoria.")

    setError({
      ...Error,
      region_id: true,
      telefono_dos: false,
      telefono_uno: false,
      sexo_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.comuna_id === ""  || valores.comuna_id === null) {
    toast.error("La comuna es obligatoria.")

    setError({
      ...Error,
      comuna_id: true,
      region_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.email === "" || valores.email === null) {
    toast.error("El email es obligatorio.")

    setError({
      ...Error,
      email: true,
      comuna_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.direccion_tipo_id === "" || valores.direccion_tipo_id === null) {
    toast.error("El tipo de calle es obligatorio.")

    setError({
      ...Error,
      direccion_tipo_id: true,
      email: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.direccion_nombre === "" || valores.direccion_nombre === null) {
    toast.error("La dirección es obligatoria.")

    setError({
      ...Error,
      direccion_nombre: true,
      direccion_tipo_id: false,
    })
    setActualizando(false)
    return (error = true)
  }

  if (valores.direccion_nro === "" || valores.direccion_nro === null) {
    toast.error("El número de dirección es obligatorio.")

    setError({
      ...Error,
      direccion_nro: true,
      direccion_nombre: false,
    })
    setActualizando(false)
    return (error = true)
  }

  // if (valores.notificar_tramite_id === "" || valores.notificar_tramite_id === null) {
  //   toast.error("Notificación trámite es obligatorio.")

  //   setError({
  //     ...Error,
  //     notificar_tramite_id: true,
  //     direccion_nro: false,
  //   })
  //   setActualizando(false)
  //   return (error = true)
  // }

  setError({
    ...Error,
    telefono_uno: false,
    telefono_dos: false,
    nombre: false,
    apellido_p: false,
    genero_id: false,
    comuna_id: false,
    region_id: false,
    fecha_nacimiento: false,
    nacionalidad_id: false,
    pais_origen_id: false,
    email: false,
    direccion_nombre: false,
    direccion_nro: false,
    direccion_tipo_id: false,
    notificar_tramite_id: false,
  })

  return (error = false)
}

export function ValidaFecha(
  year,
  EditarDatos,
  setEditarDatos,
  Error,
  setError
) {
  let anio = moment(year).format("YYYY")
  if (anio < 1900) {
    toast.error("La fecha de nacimiento es inválida.")
    setError({
      ...Error,
      fecha_nacimiento: true,
    })

    setEditarDatos({
      ...EditarDatos,
      fecha_nacimiento: "",
    })

    return
  }
}

export function handleForm(
  e,
  setEditarDatos,
  EditarDatos,
  Error,
  setError,
  setImage
) {
  if (e.target.name === "file_1") {
    if (e.target.files[0].size > 3200000) {
      toast.error("El tamaño maximo de la imagen es de 3MB.")
      return
    }

    let file = e.target.files[0]

    let file_reader = new FileReader()
    file_reader.readAsDataURL(file)

    file_reader.onload = () => {
      setImage(file_reader.result)
    }

    if (e.target.files[0].type === "image/png") {
      setEditarDatos({
        ...EditarDatos,
        [e.target.name]:
          e.target.files[0].name.split(".")[0].substr(0, 12) + ".png",
      })
      return
    } else if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      setEditarDatos({
        ...EditarDatos,
        [e.target.name]:
          e.target.files[0].name.split(".")[0].substr(0, 12) + ".jpg",
      })
      return
    } else {
      toast.error("Formato inválido, solo permite png o jpg.")
      return
    }
  }

  if (e.target.name === "telefono_uno" || e.target.name === "telefono_dos") {
    
    
    if(e.target.name === "telefono_dos"){

      if(e.target.value.length > 7 || e.target.value.length < 12){
        setEditarDatos({
          ...EditarDatos,
          [e.target.name]: e.target.value,
        })
      }

    }else if (isNaN(e.target.value) === false && e.target.value.length <= 9) {
      setEditarDatos({
        ...EditarDatos,
        [e.target.name]: e.target.value,
      })
    }
    return
  } else if (e.target.name === "fecha_nacimiento") {
    var fechanacimiento = e.target.value
    let hoy = new Date()

    let edad = moment(hoy).diff(fechanacimiento, "years")
    if (edad < 18) {
      toast.error("Debe tener al menos 18 años.")
      setError({
        ...Error,
        fecha_nacimiento: true,
      })
      setEditarDatos({
        ...EditarDatos,
        fecha_nacimiento: "",
      })

      return
    } else {
      setError({
        ...Error,
        fecha_nacimiento: false,
      })
    }
  }

  setEditarDatos({
    ...EditarDatos,
    [e.target.name]: e.target.value,
  })
}
