import React, { useState, useEffect } from "react"
import "./editar.css"
import { ToastContainer } from "react-toastify"
import { GetParametrosF } from "../../state/actions/ParametrosActions"
import { GetComunasF } from "../../state/actions/ParametrosActions"
import {
  ActualizarFicha,
  ConsultaFichaF,
} from "../../state/actions/Dashboard/DatosActions"
import { RequiereActualizarF } from "../../state/actions/Login/LoginActions"
import { useSelector, useDispatch } from "react-redux"
import {
  handleForm,
  ValidaEditarPerfil,
  ValidaFecha,
} from "../../Utils/useValidacion"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import ReactPhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import es from "react-phone-input-2/lang/es.json"

const { format } = require("../../Utils/ValidateRut")

const EditarPerfilComponent = () => {
  const dispatch = useDispatch()
  const ParametrosEditar = useSelector(
    state => state.ParametrosEditar.ParametrosEditar
  )
  const Comunas = useSelector(state => state.ParametrosEditar.Comunas)
  const DatosMiSuper = useSelector(state => state.Datosmisuper.DatosMiSuper)
  const Reload = useSelector(state => state.Datosmisuper.Reload)
  const LoginTipo = useSelector(state => state.Login.LoginTipo)
  const Actualizar = useSelector(state => state.Login.requiere_actualizar)
  const [EditarDatos, setEditarDatos] = useState({})
  const [Image, setImage] = useState("")
  const [Error, setError] = useState({})
  const [Actualizando, setActualizando] = useState(false)
  const [NumeroTlf, setNumeroTlf] = useState(0)

  useEffect(() => {
    if (Reload) {
      const ConsultaFicha = () => dispatch(ConsultaFichaF())
      ConsultaFicha()
      const Requiere = () => dispatch(RequiereActualizarF(false))
      Requiere()
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Reload])

  useEffect(() => {
    if (ParametrosEditar.length === 0) {
      const ConsultaFicha = () => dispatch(GetParametrosF())
      ConsultaFicha()
    }
    if (DatosMiSuper.length !== 0) {
      
      setNumeroTlf(DatosMiSuper.telefono_uno)

      setEditarDatos({
        run: DatosMiSuper.run,
        dv: DatosMiSuper.dv,
        nombre: DatosMiSuper.nombre,
        apellido_p: DatosMiSuper.apellido_p,
        apellido_m: DatosMiSuper.apellido_m,
        fecha_nacimiento: DatosMiSuper.fecha_nacimiento,
        genero_id: DatosMiSuper.genero.genero_id,
        sexo_id: DatosMiSuper.sexo.sexo_id,
        nacionalidad_id: DatosMiSuper.nacionalidad.nacionalidad_id || 38,
        pais_origen_id: DatosMiSuper.pais_origen.pais_origen_id || 38,
        email: DatosMiSuper.email,
        region_id: DatosMiSuper.region.region_id,
        comuna_id: DatosMiSuper.comuna.comuna_id,
        direccion_tipo_id: DatosMiSuper.direccion_tipo.direccion_tipo_id,
        direccion_nombre: DatosMiSuper.direccion_nombre,
        direccion_nro: DatosMiSuper.direccion_nro,
        direccion_nro_dpto: DatosMiSuper.direccion_nro_dpto,
        foto_text: DatosMiSuper.foto_text,
        telefono_uno: DatosMiSuper.telefono_uno,
        telefono_dos: DatosMiSuper.telefono_dos,
        facebook: DatosMiSuper.facebook,
        twitter: DatosMiSuper.twitter,
        notificar_perfil: DatosMiSuper.notificar_perfil,
        notificar_tramite_id: DatosMiSuper.notificar_tramite_id,
        cod_area: DatosMiSuper.cod_area,
      })

      const Comunas = () => dispatch(GetComunasF(DatosMiSuper.region.region_id))
      Comunas()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DatosMiSuper])

  const handleFormulario = e => {
    e.preventDefault()
    if (e.target.name === "region_id") {
      const Comunas = () => dispatch(GetComunasF(e.target.value))
      Comunas()
    }

    if(e.target.name === 'cod_area' || e.target.name === 'telefono_dos'){
      let Num = isNaN(e.target.value)
        if(!Num && e.target.name === 'cod_area' && e.target.value.length <= 4){
          setEditarDatos({
            ...EditarDatos,
            [e.target.name]: e.target.value,
          })
        }else if(!Num && e.target.name === 'telefono_dos'  && e.target.value.length <= 10){
          setEditarDatos({
            ...EditarDatos,
            [e.target.name]: e.target.value,
          })
        }
      return;
    }

    handleForm(e, setEditarDatos, EditarDatos, Error, setError, setImage)
  }

  const handleSubmit = () => {
    let validacion = ValidaEditarPerfil(
      EditarDatos,
      Error,
      setError,
      EditarDatos,
      setEditarDatos,
      setActualizando,
      NumeroTlf
    )

    if (validacion === false || validacion === "false") {
      const PostFicha = () =>
        dispatch(
          ActualizarFicha(EditarDatos, Image, setActualizando, NumeroTlf)
        )
      PostFicha()
    }
  }

  const GetFecha = year => {
    ValidaFecha(year, EditarDatos, setEditarDatos, Error, setError)
  }


  return (
    <>
      <ToastContainer closeOnClick={true} pauseOnHover={true} />

      {Actualizar === true ||
        (Actualizar === "true" && (
          <div className=" alert-requiere-ss mb-3">
            <p className=" mx-auto text-justify">
              Solicitamos completar los siguientes datos personales, los cuales son requeridos sólo la primera vez que ingreses. Si posteriormente deseas actualizarlos, podrás hacerlo accediendo en “Editar Perfil”.
            </p>

            <p className="mb-0 mt-3 py-0 text-justify">Para tu tranquilidad, la información es de carácter personal y no tendrá otro uso que el de facilitar la navegación y realizar trámites en este sitio.</p>
        
          </div>
        ))}

      <Form className="row mb-2">
        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* RUN</Form.Label>
          <Form.Control
            type="text"
            name="rut"
            disabled={true}
            value={
              EditarDatos.run ? format(EditarDatos.run + EditarDatos.dv) : ""
            }
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Nombre</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            className={Error.nombre ? "error" : ""}
            onChange={handleFormulario}
            maxLength="100"
            value={EditarDatos.nombre ? EditarDatos.nombre : ""}
            disabled={LoginTipo === "claveunica" ? true : false}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Primer Apellido</Form.Label>
          <Form.Control
            type="text"
            name="apellido_p"
            className={Error.apellido_p ? "error" : ""}
            onChange={handleFormulario}
            maxLength="100"
            value={EditarDatos.apellido_p ? EditarDatos.apellido_p : ""}
            disabled={LoginTipo === "claveunica" ? true : false}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>Segundo Apellido</Form.Label>
          <Form.Control
            type="text"
            name="apellido_m"
            onChange={handleFormulario}
            maxLength="100"
            value={EditarDatos.apellido_m ? EditarDatos.apellido_m : ""}
            disabled={LoginTipo === "claveunica" ? true : false}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Fecha Nacimiento</Form.Label>
          <Form.Control
            type="date"
            name="fecha_nacimiento"
            onChange={handleFormulario}
            className={Error.fecha_nacimiento ? "error" : ""}
            value={
              EditarDatos.fecha_nacimiento ? EditarDatos.fecha_nacimiento : ""
            }
            onBlur={() => GetFecha(EditarDatos.fecha_nacimiento)}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Género</Form.Label>
          <Form.Control
            as="select"
            className={Error.genero_id ? "error" : ""}
            onChange={handleFormulario}
            value={EditarDatos.genero_id ? EditarDatos.genero_id : ""}
            name="genero_id"
          >
          <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.genero.map((dato, i) => (
                <option key={i} value={dato.id}>
                  {dato.descripcion}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Sexo</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFormulario}
            className={Error.sexo_id ? "error" : ""}
            value={EditarDatos.sexo_id ? EditarDatos.sexo_id : ""}
            name="sexo_id"
          >
            <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.sexo.map((dato, i) => (
                <option key={i} value={dato.id}>
                  {dato.descripcion}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Nacionalidad</Form.Label>
          <Form.Control
            as="select"
            className={Error.nacionalidad_id ? "error" : ""}
            onChange={handleFormulario}
            value={
              EditarDatos.nacionalidad_id ? EditarDatos.nacionalidad_id : 38}
            name="nacionalidad_id"
          >
          <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.pais.map((dato, i) => (
                <option key={i} value={dato.codigo}>
                  {dato.gentilicio}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* País Origen</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFormulario}
            className={Error.pais_origen_id ? "error" : ""}
            value={EditarDatos.pais_origen_id ? EditarDatos.pais_origen_id : 38}
            name="pais_origen_id"
          >
          <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.pais.map((dato, i) => (
                <option key={i} value={dato.codigo}>
                  {dato.nombre}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Teléfono Celular </Form.Label>

          <ReactPhoneInput
            defaultCountry="us"
            contry="cl"
            value={NumeroTlf}
            inputProps={{
              name: "telefono_uno",
            }}
            onChange={phone => setNumeroTlf(phone)}
            localization={es}
            inputClass={Error.telefono_uno ? "error" : ""}
            containerClass={Error.telefono_uno ? "error" : ""}
            placeholder=""

          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>Código Área Fijo (Ej: +562)</Form.Label>
          <Form.Control
            type="number"
            name="cod_area"
            onChange={handleFormulario}
            value={EditarDatos.cod_area ? EditarDatos.cod_area : ""}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>Teléfono Fijo</Form.Label>
          <Form.Control
            type="text"
            name="telefono_dos"
            onChange={handleFormulario}
            className={Error.telefono_dos ? "error" : ""}
            value={EditarDatos.telefono_dos ? EditarDatos.telefono_dos : ""}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="correo@correo.com"
            name="email"
            onChange={handleFormulario}
            maxLength="100"
            className={Error.email ? "error" : ""}
            value={EditarDatos.email ? EditarDatos.email : ""}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Región</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFormulario}
            className={Error.region_id ? "error" : ""}
            value={EditarDatos.region_id ? EditarDatos.region_id : ""}
            name="region_id"
          >
          <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.regiones.map((dato, i) => (
                <option key={i} value={dato.region_id}>
                  {dato.region_nombre}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Comuna</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFormulario}
            className={Error.comuna_id ? "error" : ""}
            value={EditarDatos.comuna_id ? EditarDatos.comuna_id : ""}
            name="comuna_id"
          >
          <option value="">Seleccione</option>
            {Comunas.length !== 0 &&
              Comunas.map((dato, i) => (
                <option key={i} value={dato.comuna_id}>
                  {dato.comuna_nombre}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Tipo de Calle</Form.Label>
          <Form.Control
            as="select"
            onChange={handleFormulario}
            className={Error.direccion_tipo_id ? "error" : ""}
            value={
              EditarDatos.direccion_tipo_id ? EditarDatos.direccion_tipo_id : ""
            }
            name="direccion_tipo_id"
          >
          <option value="">Seleccione</option>
            {Object.keys(ParametrosEditar).length !== 0 &&
              ParametrosEditar.tiposdireccion.map((dato, i) => (
                <option key={i} value={dato.id}>
                  {dato.descripcion}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>* Direccion Nombre</Form.Label>
          <Form.Control
            type="text"
            name="direccion_nombre"
            onChange={handleFormulario}
            maxLength="100"
            className={Error.direccion_nombre ? "error" : ""}
            value={
              EditarDatos.direccion_nombre ? EditarDatos.direccion_nombre : ""
            }
          />
        </Form.Group>

        <Form.Group className="col-6 col-md-3 col-xl-3">
          <Form.Label>* Nro Calle</Form.Label>
          <Form.Control
            type="text"
            name="direccion_nro"
            onChange={handleFormulario}
            maxLength="50"
            className={Error.direccion_nro ? "error" : ""}
            value={EditarDatos.direccion_nro ? EditarDatos.direccion_nro : ""}
          />
        </Form.Group>

        <Form.Group className="col-6 col-md-3 col-xl-3">
          <Form.Label>Dpto o Nro de casa</Form.Label>
          <Form.Control
            type="text"
            name="direccion_nro_dpto"
            onChange={handleFormulario}
            maxlength="50"
            className={Error.direccion_nro_dpto ? "error" : ""}
            value={
              EditarDatos.direccion_nro_dpto
                ? EditarDatos.direccion_nro_dpto
                : ""
            }
          />
        </Form.Group>


        <Form.Group className="col-12 col-md-6 col-xl-3">
          <Form.Label>Foto de Perfil</Form.Label>

          <div className="input-group">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                onChange={handleFormulario}
                name="file_1"
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                {EditarDatos.file_1 ? EditarDatos.file_1 : "Seleccionar Foto"}
              </label>
            </div>
          </div>
        </Form.Group>

      </Form>

      <Button
        color="primary"
        type="submit"
        className="d-flex mx-auto btn-notif "
        disabled={Actualizando}
        onClick={() => handleSubmit()}
      >
        Guardar
        {Actualizando && (
          <div className="spinner-border spinner-border-sm ml-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </Button>
    </>
  )
}

export default EditarPerfilComponent