import { GET_PARAMETROS, GET_COMUNAS } from "../types"
import clienteAxios from "../../Utils/axios"

export function GetParametrosF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const respuesta = await clienteAxios.get("ParametrosEditar/", {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      if (respuesta.data !== "") {
        dispatch(Get_Parametros_F(respuesta.data))
      } else {
        dispatch(Get_Parametros_F([]))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const Get_Parametros_F = datos => ({
  type: GET_PARAMETROS,
  payload: datos,
})

export function GetComunasF(RegionId) {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const dataForm = new FormData()
      dataForm.append("regionId", RegionId)

      const respuesta = await clienteAxios.post("Comunas/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      if (respuesta.data !== "") {
        dispatch(Get_Comunas_F(respuesta.data))
      } else {
        dispatch(Get_Comunas_F([]))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const Get_Comunas_F = datos => ({
  type: GET_COMUNAS,
  payload: datos,
})
